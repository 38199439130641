<template>
  <v-container fluid style="margin-top: 70px">
    <v-layout row wrap justify="center">
      <v-flex xs12 md10 offset-md1>
        <v-card class="mx-auto mb-0">
          <offer-filters @resetPagination="resetPagination()" />
          <v-divider class="mx-5"></v-divider>
          <v-card-text>
            <data-list
              ref="list"
              :items="Offers.state.items"
              :server-items-length="Offers.state.total"
              @paginate="Offers.actions.applyOptions({ value: $event })"
              :loading="Offers.state.loading"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                  >
                    <offer-preview :key="item._id" :item="item" @select="selectItem(item)" />
                  </v-col>
                </v-row>
              </template>
            </data-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { inject, onMounted, ref } from '@vue/composition-api';
import OfferPreview from './OfferPreview';
import OfferFilters from './OfferFilters';
import DataList from '../../Components/DataList';

export default {
  name: 'offers-grid',
  components: {
    'offer-preview': OfferPreview,
    'offer-filters': OfferFilters,
    'data-list': DataList,
  },
  setup(props, context) {
    const { platform } = inject('Platform');
    const Offers = inject('Offers');
    const list = ref(null);
    function resetPagination() {
      if (list.value && list.value.resetPagination) {
        list.value.resetPagination();
      }
    }
    const setPreSelectItemPage = () => {
      list.value.pagination.page = Offers.state.preSelectItemPage.page;
      list.value.pagination.itemsPerPage = Offers.state.preSelectItemPage.itemsPerPage;
    };
    const selectItem = (item) => {
      Offers.actions.selectItem({ value: item });
      Offers.actions.setPreSelectItemPage({
        page: list.value.pagination.page,
        itemsPerPage: list.value.pagination.itemsPerPage,
      });
    };

    return { platform, Offers, resetPagination, list, selectItem, setPreSelectItemPage };
  },
};
</script>
<style lang="scss">
.v-btn {
  &.v-btn--has-bg {
    background: #fff !important;
    .v-btn__content {
      // color: #000 !important;
    }
  }
}
</style>
